import React from 'react';
import { Box, Text, Heading, List, ListItem } from '@chakra-ui/react';

const CGU: React.FC = () => {
  return (
    <Box p={8}>
      <Heading as="h1" fontSize="3xl" fontWeight="bold" mb={6}>
        Conditions Générales d'Utilisation (CGU)
      </Heading>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          1. Introduction
        </Heading>
        <Text>
          Les présentes Conditions Générales d’Utilisation (CGU) régissent l'utilisation de notre site internet et de notre application mobile (ci-après "Services"), accessibles via différents supports, par des professionnels de la sécurité et du secours privés.
        </Text>
        <Text mt={2}>
          En utilisant ces Services, vous acceptez les présentes CGU ainsi que notre politique de protection des données personnelles, conforme au Règlement Général sur la Protection des Données (RGPD) du 25 mai 2018.
        </Text>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          2. Informations légales
        </Heading>
        <List spacing={2}>
          <ListItem>Nom de l'entreprise : ProtectoSystem</ListItem>
          <ListItem>Siège social : 3 allée Berger, 69160 Tassin-la-Demi-Lune, France</ListItem>
          <ListItem>Email : reciponv@gmail.com</ListItem>
          <ListItem>Téléphone : 07 87 78 79 20</ListItem>
        </List>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          3. Données personnelles collectées
        </Heading>
        <Text>
          Dans le cadre de l’utilisation des Services, nous collectons et traitons les données personnelles suivantes :
        </Text>
        <List spacing={2} mt={2}>
          <ListItem>Nom et prénom</ListItem>
          <ListItem>Adresse email professionnelle</ListItem>
          <ListItem>Numéro de téléphone professionnel</ListItem>
          <ListItem>Données de géolocalisation, lorsque cela est nécessaire à la fourniture du service</ListItem>
          <ListItem>Données relatives à l'identité professionnelle (certifications, entreprise, etc.)</ListItem>
          <ListItem>Photos et vidéos dans le cadre de la prestation de services</ListItem>
        </List>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          4. Finalité du traitement des données
        </Heading>
        <Text>
          Les données collectées sont utilisées exclusivement dans le cadre des finalités suivantes :
        </Text>
        <List spacing={2} mt={2}>
          <ListItem>Fournir et améliorer les fonctionnalités de l’application</ListItem>
          <ListItem>Faciliter les interactions entre professionnels</ListItem>
          <ListItem>Gérer les abonnements payants et les paiements</ListItem>
          <ListItem>Stocker, traiter et partager des photos et vidéos professionnelles</ListItem>
          <ListItem>Assurer le support utilisateur et traiter les demandes</ListItem>
          <ListItem>Analyser les statistiques d’utilisation</ListItem>
        </List>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          5. Base légale du traitement
        </Heading>
        <Text>
          Le traitement de vos données personnelles repose sur les bases légales suivantes :
        </Text>
        <List spacing={2} mt={2}>
          <ListItem><strong>Consentement :</strong> recueilli avant la collecte de données</ListItem>
          <ListItem><strong>Contrat :</strong> exécution d’un contrat pour les services payants</ListItem>
          <ListItem><strong>Obligation légale :</strong> conservation d’informations pour répondre à des obligations</ListItem>
        </List>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          6. Droits des utilisateurs
        </Heading>
        <Text>
          Conformément au RGPD, vous disposez des droits suivants :
        </Text>
        <List spacing={2} mt={2}>
          <ListItem>Droit d’accès : Demander une copie des données que nous détenons</ListItem>
          <ListItem>Droit de rectification : Demander la correction des données inexactes</ListItem>
          <ListItem>Droit de suppression : Demander la suppression des données</ListItem>
          <ListItem>Droit de portabilité : Recevoir vos données dans un format structuré</ListItem>
          <ListItem>Droit d’opposition : Vous opposer à l’utilisation de vos données</ListItem>
        </List>
        <Text mt={2}>
          Pour exercer ces droits, contactez-nous à : reciponv@gmail.com
        </Text>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          7. Conservation des données
        </Heading>
        <Text>
          Les données personnelles, y compris les photos et vidéos, sont conservées uniquement pour la durée nécessaire aux finalités du traitement. Une fois l’abonnement résilié ou le compte inactif, les données sont supprimées ou anonymisées, sauf si une obligation légale exige leur conservation prolongée.
        </Text>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          8. Sécurité et confidentialité des données
        </Heading>
        <Text>
          Nous mettons en place des mesures techniques et organisationnelles appropriées pour garantir la sécurité et la confidentialité des données personnelles collectées, y compris les photos et vidéos. Cela inclut l’utilisation de protocoles de chiffrement, l’accès restreint aux données et des audits réguliers de sécurité.
        </Text>
        <Text mt={2}>
          En cas de violation de données personnelles, nous nous engageons à notifier l’autorité de contrôle compétente (CNIL) et les personnes concernées dans les délais prévus par le RGPD.
        </Text>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          9. Sous-traitants et hébergement des données
        </Heading>
        <Text>
          Certaines données peuvent être traitées par des sous-traitants, en particulier pour l’hébergement des données, y compris les photos et vidéos. Nous sélectionnons nos sous-traitants selon leur conformité aux exigences du RGPD. Les données peuvent être hébergées dans l’Union européenne ou dans des pays offrant un niveau de protection adéquat.
        </Text>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          10. Analyse d'impact sur la protection des données (DPIA)
        </Heading>
        <Text>
          Lorsque le traitement des données présente un risque élevé pour les droits et libertés des utilisateurs, nous réalisons une analyse d'impact sur la protection des données (DPIA) afin d'identifier et de limiter les risques.
        </Text>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          11. Modifications des CGU
        </Heading>
        <Text>
          Nous nous réservons le droit de modifier les présentes CGU à tout moment. Les modifications prendront effet dès leur publication sur le site internet ou l’application mobile. Vous serez informé de toute modification substantielle affectant vos droits via un avis spécifique dans l’application.
        </Text>
      </Box>

      <Box mt={6}>
        <Heading as="h2" fontSize="xl" fontWeight="bold" mb={2}>
          12. Contact
        </Heading>
        <Text>
          Pour toute question ou demande concernant vos données personnelles ou l'application de ces CGU, veuillez nous contacter à reciponv@gmail.com ou par courrier à :
        </Text>
        <Text mt={2}>
          ProtectoSystem, 3 allée Berger, 69160 Tassin-la-Demi-Lune, France.
        </Text>
      </Box>
    </Box>
  );
};

export default CGU;
