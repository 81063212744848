import React from 'react';
import { Box, Heading, Text, List, ListItem, Link } from '@chakra-ui/react';

const PrivacyPolicy: React.FC = () => {
  return (
    <Box p={4} maxW="800px" mx="auto">
      <Heading as="h1" size="lg" mb={4}>
        Privacy Policy
      </Heading>
      <Text fontStyle="italic" mb={4}>
        Last Updated: November 8, 2024
      </Text>
      <Text mb={4}>
        Thank you for using Protecto. Your privacy is of utmost importance to us. This Privacy Policy outlines how we
        collect, use, and protect your personal information through the Protecto web application.
      </Text>

      <Heading as="h2" size="md" mt={6} mb={2}>
        1. Information We Collect
      </Heading>
      <Text mb={4}>
        We collect various types of information in connection with the services we provide, including:
      </Text>
      <List spacing={2} pl={4} mb={4}>
        <ListItem>
          <Text as="b">Personal Information:</Text> Information such as your name, email address, phone number, and any
          other information you voluntarily provide when you sign up, update your profile, or communicate with us.
        </ListItem>
        <ListItem>
          <Text as="b">Usage Data:</Text> Information about your interactions with the app, such as pages visited,
          features used, and other usage statistics.
        </ListItem>
        <ListItem>
          <Text as="b">Device and Technical Information:</Text> Information such as your IP address, browser type,
          device type, operating system, and settings, which helps us ensure a smooth user experience.
        </ListItem>
        <ListItem>
          <Text as="b">Location Information:</Text> We may collect location data if you provide your consent, enabling
          us to improve our services based on your geographic location.
        </ListItem>
      </List>

      <Heading as="h2" size="md" mt={6} mb={2}>
        2. How We Use Your Information
      </Heading>
      <List spacing={2} pl={4} mb={4}>
        <ListItem>Provide and maintain the Protecto services.</ListItem>
        <ListItem>Enhance user experience by customizing the content displayed on the app.</ListItem>
        <ListItem>Communicate with you, respond to inquiries, provide customer support, and send updates.</ListItem>
        <ListItem>Monitor usage patterns, perform analytics, and troubleshoot technical issues.</ListItem>
        <ListItem>Improve and develop new features for the app.</ListItem>
        <ListItem>Comply with legal obligations and protect our legal rights.</ListItem>
      </List>

      <Heading as="h2" size="md" mt={6} mb={2}>
        3. Sharing Your Information
      </Heading>
      <Text mb={4}>
        We do not sell or rent your personal information to third parties. However, we may share your information in the
        following circumstances:
      </Text>
      <List spacing={2} pl={4} mb={4}>
        <ListItem>
          <Text as="b">With Service Providers:</Text> We may share your information with third-party vendors who assist
          us with app functionality, analytics, customer support, and related services.
        </ListItem>
        <ListItem>
          <Text as="b">Legal Requirements:</Text> We may disclose your information if required by law or in response to
          a lawful request from law enforcement authorities or to protect our legal rights.
        </ListItem>
        <ListItem>
          <Text as="b">Business Transfers:</Text> In the event of a merger, acquisition, or asset sale, your
          information may be transferred to a third party.
        </ListItem>
      </List>

      <Heading as="h2" size="md" mt={6} mb={2}>
        4. Data Security
      </Heading>
      <Text mb={4}>
        We use industry-standard security measures to protect your personal information. However, no method of
        transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </Text>

      <Heading as="h2" size="md" mt={6} mb={2}>
        5. Data Retention
      </Heading>
      <Text mb={4}>
        We retain your information for as long as necessary to provide our services, comply with legal obligations,
        resolve disputes, and enforce our agreements.
      </Text>

      <Heading as="h2" size="md" mt={6} mb={2}>
        6. Your Rights and Choices
      </Heading>
      <Text mb={4}>
        Depending on your jurisdiction, you may have the right to:
      </Text>
      <List spacing={2} pl={4} mb={4}>
        <ListItem>Access the personal information we hold about you.</ListItem>
        <ListItem>Correct any inaccurate information.</ListItem>
        <ListItem>Request deletion of your information.</ListItem>
        <ListItem>Restrict or object to the processing of your data.</ListItem>
        <ListItem>Withdraw consent where we rely on it for processing.</ListItem>
      </List>
      <Text mb={4}>
        To exercise these rights, please contact us at{' '}
        <Link href="mailto:reciponv@gmail.com" color="blue.500">
          reciponv@gmail.com
        </Link>
        .
      </Text>

      <Heading as="h2" size="md" mt={6} mb={2}>
        7. Children's Privacy
      </Heading>
      <Text mb={4}>
        Protecto does not knowingly collect or solicit personal information from anyone under the age of 13. If we
        learn we have collected personal information from a child under 13, we will delete that information promptly.
      </Text>

      <Heading as="h2" size="md" mt={6} mb={2}>
        8. Changes to this Privacy Policy
      </Heading>
      <Text mb={4}>
        We may update this Privacy Policy from time to time. We encourage you to review this page periodically for any
        changes. The "Last Updated" date at the top indicates when the latest changes were made.
      </Text>

      <Heading as="h2" size="md" mt={6} mb={2}>
        9. Contact Us
      </Heading>
      <Text mb={4}>
        If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:
      </Text>
      <Text fontWeight="bold">
        Protecto Support Team
        <br />
        Email: <Link href="mailto:reciponv@gmail.com" color="blue.500">reciponv@gmail.com</Link>
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
